/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
//Custom libraries
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
//UI
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RoomIcon from "@mui/icons-material/Room";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { StyledGrid, StyledContainer, StyledTypography, StyledFooter } from "@hcl-commerce-store-sdk/react-component";
import { STRStore } from "solteq/src/services/STRStore.service";
import { useSite } from "_foundation/hooks/useSite";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as FooterLogo } from "solteq/src/assets/images/str_logo.svg";
import styled from "@mui/styled-engine-sc";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { userLastUpdatedSelector } from "redux/selectors/user";

function Footer(props: any) {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const theme = useTheme();
  useSelector(userLastUpdatedSelector);

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const marketingTextProps = useMemo(
    () => ({
      widget: {
        id: `footer-marketing-text`,
        widgetName: "content-recommendation-widget-footer-marketing-text",
        name: "FooterMarketingTextWidget",
        properties: {
          emsName: "FooterMarketingText",
        },
      },
      page: { name: "" },
    }),
    []
  );

  const CompanyLinks = () => {
    const LinksList = () => {
      return mySite != null ? (
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-links-list`,
              widgetName: "content-recommendation-widget-footer-links-list",
              name: "LinksListWidget",
              properties: {
                emsName: "Links_List",
              },
            },
            page: { name: "" },
          }}
        />
      ) : null;
    };
    return (
      <div className="contact-us">
        <StyledTypography variant="subtitle1">{t("Footer.CompanyLinks.Label")}</StyledTypography>
        <StyledTypography variant="caption">
          <LinksList />
        </StyledTypography>
      </div>
    );
  };

  const Contact = () => {
    const [contactInfo, setContactInfo] = React.useState<any>(null);

    React.useEffect(() => {
      let sub = true; // Cancel the setState after component unmounts this way
      // contact or location ?
      STRStore.getContactInfo().then((response: any) => {
        if (sub) {
          setContactInfo(response);
        }
      });

      return () => {
        sub = false;
      };
    }, []);

    return (
      <div className="conctact">
        <StyledTypography variant="subtitle1">{t("Footer.Contact.Title")}</StyledTypography>
        <div>
          <StyledTypography variant="caption" display="block">
            <RoomIcon fontSize="small" />
            <span> Suomi Trading Oy</span>
          </StyledTypography>
          {contactInfo?.address.addressLine.map((line, index) => (
            <StyledTypography variant="caption" display="block" key={index}>
              {line}
            </StyledTypography>
          ))}
          <StyledTypography variant="caption" display="block">
            {contactInfo?.address.zipOrPostalCode} {contactInfo?.address.city}
          </StyledTypography>
          {contactInfo?.address?.country && (
            <StyledTypography variant="caption" display="block">
              {t(`CommerceEnvironment.country.${contactInfo?.address.country}`)}
            </StyledTypography>
          )}
          <br />
          <StyledTypography variant="caption" display="block">
            {t("Footer.Contact.CustomerService")} (8-16):
          </StyledTypography>
          <div className="icon-text">
            <SmartphoneIcon fontSize="small" />
            <StyledTypography variant="caption" display="block">
              {" "}
              {contactInfo?.telephone1}
            </StyledTypography>
          </div>

          <div className="icon-text">
            <MailOutlineIcon fontSize="small" />
            <StyledTypography variant="caption" display="block">
              {contactInfo?.emailAddress1}
            </StyledTypography>
          </div>
        </div>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className="copyright">
        <StyledTypography variant="caption">
          &copy; <span>{new Date().getFullYear()}</span>
          <span> {t("Footer.Copyright")}</span>
        </StyledTypography>
      </div>
    );
  };

  return (
    <>
      <StyledFooter>
        <StyledContainer>
          <StyledGrid container spacing={2} className="footer-top">
            <StyledGrid item xs={6} sm={4} lg={4}>
              <CompanyLinks />
            </StyledGrid>

            {!isMobile && (
              <StyledGrid item>
                <Divider orientation="vertical" />
              </StyledGrid>
            )}

            <StyledGrid item xs={12} sm={4} lg={4}>
              <Contact />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container direction="column" alignItems="center" className="footer-bottom">
            <StyledMarketingTextContainer item>
              <ContentRecommendationWidget {...marketingTextProps} />
            </StyledMarketingTextContainer>
            <StyledGrid item>
              <StyledFooterLogo />
            </StyledGrid>
            <StyledGrid item>
              <Copyright />
            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
      </StyledFooter>
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

const StyledMarketingTextContainer = styled(StyledGrid)`
  p,
  span {
    font-size: 0.8em;
    font-family: "Rubik";
    font-style: italic;
    text-align: center;
  }
`;

const StyledFooterLogo = styled(FooterLogo)`
  width: 8em;
  display: block;
  & path,
  polygon {
    fill: #ffffff;

    &.cls-2 {
      stroke: #ffffff;
      stroke-width: 0.3px;
    }
  }
`;

export default Footer;
