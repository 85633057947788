/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, { Dispatch } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import getDisplayName from "react-display-name";
//Foundation libraries
import { site } from "./_foundation/constants/site";
import { initAxios } from "./_foundation/axios/axiosConfig";
import { initSite, useSite } from "./_foundation/hooks/useSite";
import LoginGuard from "./_foundation/guard/LoginGuard";
import { storageSessionHandler } from "./_foundation/utils/storageUtil";
import storeService from "./_foundation/apis/transaction/store.service";
import { PRODUCTION } from "./_foundation/constants/common";
import shippingInfoService from "./_foundation/apis/transaction/shippingInfo.service";
//Custom libraries
import { DISCOVER_FEATURE, EMPTY_STRING } from "./constants/common";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Extensions } from "./components/extensions";
import { useCSRForUser } from "./_foundation/hooks/useCSRForUser";
import SuccessMessageSnackbar from "./components/widgets/message-snackbar/SuccessMessageSnackbar";
import ErrorMessageSnackbar from "./components/widgets/message-snackbar/ErrorMessageSnackbar";
import { IFRAME_RESIZER } from "./_foundation/constants/csr";
//Redux and context
import { forUserIdSelector, loginStatusSelector } from "./redux/selectors/user";
import { INIT_STATE_FROM_STORAGE_ACTION, LISTEN_USER_FROM_STORAGE_ACTION } from "./redux/actions/user";
import { GET_COUNTRY_STATE_LIST_ACTION } from "./redux/actions/country";
import { useStoreShippingModeValue } from "./_foundation/context/store-shipping-mode-context";
//UI
import { StyledWrapper } from "./components/StyledUI";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import "./App.scss";
//GA360
//UA
import GTMDLService from "./_foundation/gtm/ua/gtmDataLayer.service";
//GA4
import GA4GTMDLService from "./_foundation/gtm/ga4/gtmDataLayer.service";
import SelectionDialog from "solteq/src/components/common/SelectionDialog";
import { SelectionDialogContext, SelectionDialogContextProvider } from "solteq/src/contexts/selectionDialog.context";
import { SolteqMainContainer } from "solteq/src/components/SolteqMainContainer";
import { usePayloadBase } from "solteq/src/hooks/usePayloadBase";
import { PreviewNonManagedLayout } from "./_foundation/preview/layout/preview-non-managed-layout";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    //scroll to top on path change.
    setTimeout(() => {
      window["topOrSelf"] && window["topOrSelf"].scrollTo(0, 0);
    });
  }, [pathname]);
  return null;
};

const App: React.FC = (props: any) => {
  const widgetName = getDisplayName(App);
  const loggedIn = useSelector(loginStatusSelector);
  const forUserId = useSelector(forUserIdSelector);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite, storeDisplayName } = useSite();
  const { receiveParentMessage } = useCSRForUser();

  const { payloadBase } = usePayloadBase(widgetName);
  const [discover, setDiscover] = React.useState<boolean>(false);

  const { setStoreShippingMode } = useStoreShippingModeValue();
  const isStoreOpen = mySite?.storeCfg?.state === "open";
  const isStoreClosed = mySite?.storeCfg?.state === "closed";
  const { t } = useTranslation();
  const storeClosedMsg = t("Header.StoreClosed.Msg", {
    storeName: storeDisplayName,
  });

  initAxios(dispatch);

  /**
   * Function to check Discover is enabled for store based on storeId
   *
   * @param storeID
   */
  const isDiscoverEnabled = (storeID: string) => {
    const payload = {
      storeId: storeID,
      ...payloadBase,
    };
    storeService
      .getStoreEnabledFeaturesList(payload)
      .then((res) => {
        if (res.data && res.data.resultList) {
          setDiscover(res.data.resultList.includes(DISCOVER_FEATURE));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    if (mySite) {
      dispatch(INIT_STATE_FROM_STORAGE_ACTION({ ...payloadBase }));
      dispatch(GET_COUNTRY_STATE_LIST_ACTION({ ...payloadBase }));
      storageSessionHandler.triggerUserStorageListener(() =>
        dispatch(LISTEN_USER_FROM_STORAGE_ACTION({ ...payloadBase }))
      );
      isDiscoverEnabled(mySite.storeID);
      shippingInfoService
        .getAllowableShippingModes(mySite.storeID, undefined, undefined, undefined, { widget: widgetName })
        .then((res) => {
          setStoreShippingMode(res.data?.usableShippingMode ?? []);
        })
        .catch((e) => console.log("fail to get store shipping mode", e));
      //GA360
      if (mySite.enableGA) {
        if (mySite.enableUA) {
          GTMDLService.initializeGTM(mySite.gtmID, mySite.gtmAuth, mySite.gtmPreview);
        }
        if (mySite.enableGA4 && !mySite.enableUA) {
          GA4GTMDLService.initializeGTM(mySite.gtmID, mySite.gtmAuth, mySite.gtmPreview);
        }
      }
    } else {
      initSite(site, dispatch);
    }
  }, [mySite, dispatch]);

  React.useEffect(() => {
    if (forUserId) {
      window[IFRAME_RESIZER] = {
        onMessage: receiveParentMessage,
      };
    } else {
      window[IFRAME_RESIZER] = undefined;
    }
  }, [forUserId]);

  React.useEffect(() => {
    // SQSTRCOM-631: Let's load the zendesk script this way to make more certain that the zE global variable is defined and in scope
    const zendeskScript = document.createElement("script");
    zendeskScript.src = `${process.env.PUBLIC_URL}/zendesk.js`;
    zendeskScript.defer = true;
    setTimeout(() => document.body.appendChild(zendeskScript), 500);

    return () => {
      document.body.removeChild(zendeskScript);
    };
  }, []);

  const baseName = process.env.REACT_APP_ROUTER_BASENAME ? { basename: process.env.REACT_APP_ROUTER_BASENAME } : {};

  // public url path for accessing discoverui.js file.
  const publicUrlPath = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : EMPTY_STRING;

  // load the site as soon as available -- but in CSR scenarios, wait for the user and context-fetch to
  //   complete in order -- the person @self call HAS TO complete before anything else to establish session
  return mySite && isStoreOpen && (!forUserId || loggedIn) ? (
    <BrowserRouter {...baseName}>
      <StyledWrapper data-testid="app-wrapper">
        <SelectionDialogContextProvider>
          <SelectionDialogContext.Consumer>
            {(value) => <SelectionDialog {...value?.props} />}
          </SelectionDialogContext.Consumer>
          <SuccessMessageSnackbar />
          <ErrorMessageSnackbar />
          <StyledGrid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            className="full-viewport-height">
            <StyledGrid item xs={false} className="sticky-header" id="headerWrapper">
              <Header loggedIn={loggedIn} />
              <LoginGuard />
              <PreviewNonManagedLayout />
              <ScrollToTop />
              <Helmet>
                <meta charSet="utf-8" />
                <title>{`${storeDisplayName}`}</title>
                {discover && (
                  <script
                    src={`${publicUrlPath}/discover/discoverui.js?q=${Date.now()}`}
                    type="text/javascript"
                    async
                  />
                )}
                {window[IFRAME_RESIZER] && (
                  <script src="/iframeResizer.contentWindow.min.js" type="text/javascript" async />
                )}
                <script
                  id="ze-snippet"
                  src="https://static.zdassets.com/ekr/snippet.js?key=7bc5d28c-1883-4100-b95d-ff4a6a7fbef6"
                  type="text/javascript"
                />
              </Helmet>
            </StyledGrid>
            <SolteqMainContainer />
            <StyledGrid item xs={false} className="footer-container">
              <Footer />
            </StyledGrid>
            {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
          </StyledGrid>
        </SelectionDialogContextProvider>
      </StyledWrapper>
    </BrowserRouter>
  ) : isStoreClosed ? (
    <Typography variant="h4">{storeClosedMsg}</Typography>
  ) : null;
};

export default App;
